@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Noto Sans TC" !important;
}

#announcement-table {
  .v-data-table:not(.v-data-table--mobile) {
    table {
      th.table-text {
        text-align: center !important;
        padding: 12px 16px !important;
        color: #e69a28 !important;
        font-size: 1rem !important;
        border-bottom: 2px solid #e69a28 !important;
        font-weight: 500;
      }
      tbody tr:hover {
        background-color: #f3f4f6 !important;
      }
      td {
        padding: 24px 16px !important;
      }

      .col-date,
      .col-type {
        white-space: nowrap;
        width: 0;
      }

      .col-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 0;
      }
    }
  }

  .v-data-table.v-data-table--mobile {
    .v-data-table__mobile-table-row {
      .v-data-table__mobile-row__header {
        display: none;
      }
      .v-data-table__mobile-row:first-of-type {
        color: #e69a28;
        padding: 0.5rem 1rem;
        display: inline-block;
        min-height: unset;
      }
      .v-data-table__mobile-row:nth-of-type(2) {
        color: #e69a28;
        padding: 0.5rem 0;
        display: inline-block;
        min-height: unset;
      }
      .v-data-table__mobile-row:nth-of-type(3) {
        padding: 0.5rem 1rem;
        .v-data-table__mobile-row__cell {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  nav {
    button {
      box-shadow: unset !important;
    }
    .v-pagination__item--active {
      background-color: #e69a28 !important;
    }
  }
}

#app {
  table {
    th {
      text-align: center !important;
      padding: 12px 16px !important;
      color: #e69a28 !important;
      font-size: 1rem !important;
      border-bottom: 2px solid #e69a28 !important;
      font-weight: 500;
    }
    tbody tr:hover {
      background-color: unset;
    }

    td {
      font-size: 16px;
      line-height: 24px;
      color: #1f2937;
    }
  }

  .v-data-table.v-data-table--mobile {
    .v-data-table__mobile-row__header {
      white-space: nowrap;
      color: #e69a28;
      white-space: nowrap;
    }
  }

  .quotation-table {
    .v-input__control {
      min-height: 40px;
      .v-input__slot {
        padding: 0 1rem;
        border: 1px solid #d1d5db;
        border-radius: 6px;
        box-shadow: unset;
      }
    }

    .col-count {
      width: 100%;
      min-width: 100px;
      .v-input {
        border: 1px solid #d1d5db;
        border-radius: 6px;
        .v-input__control {
          border-left: 1px solid #d1d5db;
          border-right: 1px solid #d1d5db;
          border-radius: 0;
          .v-input__slot {
            box-shadow: unset;
            padding: 0;
            border: unset;
            input {
              text-align: center;
            }
          }
        }
        .v-input__prepend-outer,
        .v-input__append-outer {
          margin: 7px 3px;
        }
      }
    }
    .col-total {
      width: 100%;
      min-width: 100px;
      white-space: nowrap;
      text-align: end !important;
      font-weight: 500;
      color: #bd2828 !important;
    }
    .col-price {
      width: 100%;
      min-width: 140px;
      white-space: nowrap;
      text-align: end !important;
      font-weight: 500;
      .text-original-price {
        margin-right: 0.5rem;
        color: #6b7280;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: line-through;
      }
    }
    .col-product .mdi-plus-circle {
      color: #6998ab !important;
    }
    &:not(.v-data-table--mobile) {
      .hide-category {
        display: none;
      }
      td {
        padding: 1rem !important;
        border-bottom: unset;
        border-top: 1px solid #e5e7eb;
      }
    }

    .hide-icon {
      .v-input__icon {
        visibility: hidden;
      }
    }

    .row-hide-category {
      td {
        padding-top: 0 !important;
        border-top: unset;
      }
    }

    .total-price {
      margin-right: 40px;
      text-align: end;
      .money {
        color: #bd2828;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-left: 1rem;
      }
    }
  }

  .checkout-table {
    @media only screen and (min-width: 768px) {
      .col-count {
        text-align: end !important;
      }
      .col-price {
        text-align: end !important;
      }
      .col-total {
        text-align: end !important;
      }
    }
    .col-count {
      text-align: center !important;
    }
    .col-price {
      white-space: nowrap;
      text-align: end !important;
      font-weight: 500;
      .text-original-price {
        margin-right: 0.5rem;
        color: #6b7280;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: line-through;
      }
    }
    .col-total {
      white-space: nowrap;
      text-align: end !important;
      font-weight: 500;
      color: #1f2937 !important;
    }
    .col-product .mdi-plus-circle {
      color: #6998ab !important;
    }
    &:not(.v-data-table--mobile) {
      .hide-category {
        display: none;
      }
      td {
        padding: 1rem !important;
        border-bottom: unset;
        border-top: 1px solid #e5e7eb;
      }
    }
    .row-hide-category {
      td {
        padding-top: 0 !important;
        border-top: unset;
      }
    }
  }
  .checkout-form,
  .contact-form {
    border-radius: 4px;
    input,
    select,
    textarea {
      padding: 8px 16px;
      border: 1px solid #d1d5db;
      border-radius: 6px;
      resize: none;
    }
    select {
      background-color: white;
      /* inline SVG */
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: auto 50%;
      padding: 8px 16px;
      border: 1px solid #d1d5db;
      border-radius: 6px;
      // disable default appearance
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
    }
  }

  #order {
    .v-input {
      border: 1px solid #d1d5db;
      border-radius: 6px;
      .v-input__slot {
        box-shadow: unset;
      }
    }
  }
  .v-expansion-panels {
    display: block;
    .order-card {
      border: 1px solid #e5e7eb;
      border-radius: 4px;
      margin-bottom: 48px;
      .v-expansion-panel {
        background: #f3f4f6;
        color: #6b7280;
        &:before {
          box-shadow: unset;
        }
        .v-expansion-panel-header {
          padding: 1rem;
          min-height: unset;
          .header-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            .customer {
              color: #1f2937;
              margin-right: 0.5rem;
            }
            .order-number {
              color: #6b7280;
            }
            .state.未處理 {
              color: #BD2828;
            }
            .state.已處理 {
              color: #26AF45;
            }
          }
        }
        .v-expansion-panel-content__wrap {
          padding: 0 1rem 1rem 1rem;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
